(function($, window) {

    var navWidth = -1,
        $outer = null; // initialized in onLoad


    window.AGA = {

        /**
         * Attach functions on the click events for more/back buttons in the menu
         * on large screens, that allow to switch to other menu items.
         */
        enableMenuPaging: function() {
            var $firstMenu = $('.menu:eq(0)');
            $('.menu > li[data-action]').each(function() {
                var $li = $(this);
                $li.children('a').on('click', function() {
                    var offset = $firstMenu.data('offset');
                    if (typeof(offset) === 'undefined') {
                        offset = 0;
                    }
                    $firstMenu.removeClass(`offset-${offset}`);
                    if ($li.data('action') === 'menu-prev') {
                        offset--;
                    } else if ($li.data('action') === 'menu-next') {
                        offset++;
                    } // ignore other values, e.g. `menu-toggle`
                    $firstMenu.addClass(`offset-${offset}`)
                        .data('offset', offset);
                    $(this).blur(); // remove outline from element
                });
            });

            // determine in which page the current menu item is, ensure that
            // page is shown initially
            var $active = $('li.active, li.subactive');
            if ($active.size() > 0) {
                var offset = $('.menu').index($active.first().parent());
                $firstMenu.addClass(`offset-${offset}`)
                    .data('offset', offset);
            }
        },

        /**
         * Attach a function on the click event of the toggle button in the menu
         * on smaller screens, that allows to show/hide the menu.
         */
        enableMenuToggling: function() {
            var $nav = $('#nav'),
                svgToggle = document.querySelector(
                    '[data-action="menu-toggle"] svg'),
                svgAnim = svgToggle.querySelector('#overlay'),
                menuOpen = false,
                svgOpen = true;
            $('.menu > li[data-action="menu-toggle"] a').on('click', function() {
                $nav.toggleClass('fullscreen');
                document.body.classList.toggle('disable-scrolling');

                menuOpen = !menuOpen;
                if (menuOpen !== svgOpen) {
                    for (let kt of svgToggle.querySelectorAll('[keyTimes]')) {
                        let from = kt.getAttribute('from'),
                            into = kt.getAttribute('to');
                        kt.setAttribute('from', into);
                        kt.setAttribute('to', from);
                    }
                    svgOpen = menuOpen;
                }
                if (typeof svgAnim.beginElement === 'function') {
                    svgAnim.beginElement();
                }
            });
        },

        /**
         * Attach functions on the mouse over and mouse out events, but with
         * a slight delay before actually executing the functions. Also ensures
         * that the mouse out function is not called when a user moves their
         * mouse away and then quickly back again.
         *
         * Very useful for tooltips, for example.
         */
        mouseOverOut: function(over, out) {
            $(this).mouseover(
                function() {
                    if (this.timeoutIdOver) {
                        clearTimeout(this.timeoutIdOver);
                    }
                    // clear out too
                    if (this.timeoutIdOut) {
                        clearTimeout(this.timeoutIdOut);
                    }
                    this.timeoutIdOver = setTimeout(over, 100);
                })
            .mouseout(
                function() {
                    if (this.timeoutIdOut) {
                        clearTimeout(this.timeoutIdOut);
                    }
                    // clear over too
                    if (this.timeoutIdOver) {
                        clearTimeout(this.timeoutIdOver);
                    }
                    this.timeoutIdOut = setTimeout(out, 400); // give user some time to move to popup with mouse
                }
            );
        },

        /**
         * Move `this`, an element, inside the screen by shifting it left or right.
         * Assumes that `this` is positioned absolutely.
         */
        moveInScreen: function() {
            var $el = $(this),
                offset = $el.offset();
            if (navWidth < 0) {
                navWidth = parseInt($outer.css('left')) || 0;
            }
            if (offset.left < navWidth) {
                $el.css('left', $el.position().left + (navWidth - offset.left) + 5);
            } else {
                var elW = $el.outerWidth(),
                    docW = $('body').width();
                if (offset.left + elW > docW) {
                    $el.css('left', $el.position().left - (offset.left + elW - docW) - 5);
                }
            }
        },

        /**
         * Return a function that can be called many times, but will ensure that
         * the given \c func function is only called every \c delay milliseconds.
         *
         * Very useful for throttling event handlers, such as window resize.
         */
        throttle: function(delay, func) {
            var timeout = null,
                calledInBetween = false;
            return function() {
                var context = this,
                    args = arguments,
                    delayed = function() {
                        func.apply(context, args);
                        if (!calledInBetween) {
                            // not called, so stop interval
                            clearInterval(timeout);
                            timeout = null;
                        } else {
                            // we have been called in between, so we continue the
                            // calling interval and we reset calledInBetween
                            calledInBetween = false;
                        }
                    };
                if (timeout === null) {
                    timeout = setInterval(delayed, delay);
                } else {
                    calledInBetween = true;
                }
            };
        },

        /**
         * Update the minimum height of \c this thesis container.
         * If the cover has not been loaded yet, sets an onload handler to update
         * the minimum height as soon as the image is loaded.
         */
        updateThesisMinHeight: function() {
            var t = $(this),
                c = t.find('img').get(0),
                mh = null;
            if (!c.complete || c.naturalWidth === 0) {
                c.addEventListener('load', function() {
                    AGA.updateThesisMinHeight.call(t.get(0));
                });
            }
            t.children('.thesis-image, .thesis-description').each(function(i) {
                var e = $(this);
                // reset position from 'absolute' to default
                e.css('position', 'static');
                // now, find the height of the element
                var h = e.outerHeight();
                // clear position so it is absolute again
                e.css('position', '');
                // update max height
                mh = (mh === null ? h : Math.max(mh, h));
            });
            // now set the min-height of the container to the max-height of the
            // children, so that the children fit in the container
            t.css('min-height', mh);
        },

    };


    $(function() {
        $outer = $('#outer');
        document.body.classList.add('js-enabled');

        // enable more/back menu items
        AGA.enableMenuPaging();

        // enable menu toggle item (for small screens)
        AGA.enableMenuToggling();

        // move tooltips on index, if needed
        $('.index-image-tooltip').each(function(i) {
            AGA.moveInScreen.call(this);
            var $tooltip = $(this),
                $image = $tooltip.parent();
            AGA.mouseOverOut.call($image.get(0), function() {
                $tooltip.addClass('active');
            }, function() {
                $tooltip.removeClass('active');
            });
        });
        $(window).on('resize', AGA.throttle(100, function() {
            navWidth = -1;
            $('.index-image-tooltip').each(function(i) {
                $(this).css('left', '');
                AGA.moveInScreen.call(this);
            });
        }));

        // enable card clicking on the staff page and theses page
        var $members = $('.member, .thesis-container');
        $members.on('click', function(e) {
            if (e.target.nodeName !== 'A') {
                var $this = $(this);
                $this.toggleClass('rotated');
                $members.not($this).removeClass('rotated');
            }
        });

        // update min-height of all cards on theses page
        var updateMinHeight = function() {
            $('.thesis-container').each(AGA.updateThesisMinHeight);
        };
        $(window).on('resize', AGA.throttle(100, updateMinHeight));
        updateMinHeight();

    });

}(jQuery, window));
